import { MultiLanguageText } from "@src/contexts";

export const getLabelinLanguage = (
  labels: MultiLanguageText[] = [],
  language?: string,
  key: keyof MultiLanguageText = "text",
) => {
  const label = labels?.find((e) => e.language === language);
  return label?.[key] || "";
};

export const getURLinLanguage = (
  labels: { language: string; url: string }[] = [],
  language?: string,
) => {
  if (!labels || !language) return "";

  const match = labels?.find((e) => e.language === language);
  return match?.url || "";
};
